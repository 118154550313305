import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../components/Layout';

const ArticlesIndex = () => (
  <Layout
    title="Articles about surveying and RPR (Real Property Report) requirements relating to municipal bylaws permits and related issues."
    description="Want to learn more about the bylaws in Calgary, Red Deer and Edmonton and how they effect an RPR / Real Property Reports? This a in depth blog style resource to help you understand the details of the many permit and by-law issues."
    keywords=""
  >
    <main>
      <h1>Articles</h1>
      <ul>
        <li>
          <Link to="/articles/what-is-a-land-survey">What Is a Land Survey</Link>
          <br />A land survey is a crucial process conducted by professional surveyors to accurately
          measure, map, and verify the boundaries of a property. This service is essential for real
          estate transactions, construction planning, and avoiding encroachment issues near property
          boundaries. Surveys confirm legal descriptions of property lines, ensuring compliance with
          zoning regulations and preventing potential disputes over ownership and minimizing the
          risk of legal issues.
        </li>
        <li>
          <Link to="/articles/what-is-a-plot-plan">What Is a Plot Plan</Link>
          <br />A plot plan is a detailed technical drawing or map that provides an accurate
          representation of a property’s boundaries, structures, natural features, legal
          restrictions, and other relevant information. It serves as a foundational document for
          various real estate and construction activities by ensuring clarity in ownership,
          compliance with regulations, and efficient project planning.
        </li>
        {/*
        <li>
          <Link to="/articles/deck-permit-requirements-in-calgary">
            Deck Permit Requirements in Calgary
          </Link>
          <br />
          Building a deck in Calgary may require a permit if it meets specific conditions, such as
          height considerations or being attached to a house. Ignoring the permit can lead to fines,
          forced modifications, or teardowns, affecting future home sales. Always consult local
          regulations and consider professional advice to ensure legal and safe construction.
        </li>
        */}
        <li>
          <Link to="/articles/certificate-of-title-alberta">Certificate of Title Alberta</Link>
          <br />A land title certificate in Alberta is an official document proving property
          ownership. It includes ownership details, legal descriptions, and registered encumbrances
          like liens or mortgages. Obtainable online via SPIN2 or in person at a registry office, it
          ensures accurate property transactions, legal security, and dispute resolution for owners,
          buyers, and lenders.
        </li>
        <li>
          <Link to="/articles/bare-land-condo-vs-conventional-condo">
            Bare Land Condo vs Conventional Condo
          </Link>
          <br />
          Bare land condos offer ownership of land and structure with more control but require
          maintenance. Conventional condos provide convenience with shared upkeep and higher fees.
          The choice depends on whether you prioritize independence or ease of living. Consulting a
          professional can help determine the best fit.
        </li>
        <li>
          <Link to="/articles/land-surveyors-and-rprs">
            Land Surveyors and RPRs - What are they? An Outsider's Perspective
          </Link>
          <br />
          Discover the vital role of land surveyors and Real Property Reports (RPRs) in the dynamic
          world of real estate and construction. Gain insights into the precision and expertise
          behind property boundaries, mapping, and urban planning. Explore the hidden realm of
          measurements and boundary markings that shape our communities.
        </li>
        <li>
          <Link to="/articles/overland-drainage-right-of-ways">
            Overland Drainage Right of Ways
          </Link>
          <br />A Right of Way (R.W.) is a legally registered agreement associated with a land
          title, defining specific usage rights and restrictions for a portion of land. An Overland
          Drainage Right of Way can limit property owners' ability to inhibit drainage or install
          structures within this designated area.
        </li>
        <li>
          <Link to="/articles/gas-coop-utility-right-of-ways-rural-properties">
            Gas CO-OP and Utility Right of Way for Rural Properties
          </Link>
          <br />
          In rural Alberta, the installation and management of natural gas infrastructure differ
          significantly from urban areas. Urban regions employ Utility Right Of Way Plans granting
          specific rights and obligations related to the infrastructure's location, outlined in on
          Real Property Reports (R.P.R.).
        </li>
        <li>
          <Link to="/articles/utility-right-of-ways">Utility Right of Ways</Link>
          <br />
          Utility Right of Ways are critical yet often overlooked aspects of property ownership and
          real estate transactions. These designated portions of land, owned by one property owner
          but granting access rights to another, are common found throughout Alberta.
        </li>
        <li>
          <Link to="/articles/relaxation-and-encroachment-info-2022">
            Relaxation and Encroachment Information updated for 2022
          </Link>
          <br />
          Encroachment agreements, legal arrangements resolving disputes arising when one party's
          property improvements encroach onto another's, are crucial for addressing issues like
          fences, decks, retaining walls, or other property enhancements that may infringe upon
          municipal property or rights of way.
        </li>
        <li>
          <Link to="/articles/calgary-rpr-window-wells-air-conditioning">
            Calgary Real Property Reports, Window Wells & Air Conditioning Units: What You Need To
            Know
          </Link>
          <br />
          The City of Calgary updated Land Use Byload 1P2007 in 2008 introducing significant changes
          regarding window wells and air conditioning units, affecting real estate transactions and
          homeowners. It is crucial for buyers and sellers to understand the implications of these
          changes.
        </li>
      </ul>
    </main>
  </Layout>
);

export default ArticlesIndex;
